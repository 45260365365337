import React from 'react'
import styled from 'styled-components'
import { Switch, Route } from 'react-router-dom'
import { Icon, ItemsSidebar, FirebaseList, Welcome } from '../components'
import EditorView from './EditorView'
import { startCase } from 'lodash'
import ActivityOverlay from '../components/ActivityOverlay'

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  overflow: hidden;
`

const Collection = ({ path, href = path, iconName, itemName, editor, groupBy, ...props }) => (
  <FirebaseList path={path} loading={<ActivityOverlay fillParent />}>
    {items => (
      <Wrapper>
        <ItemsSidebar href={href} items={items} groupBy={groupBy} itemName={itemName} {...props} />
        <Switch>
          <Route
            path={`/${href}/:itemId`}
            render={({ match }) => (
              <EditorView
                name={startCase(itemName)}
                path={`${path}/${match.params.itemId}`}
                href={match.url}
                render={props => editor({ items, ...props })}
              />
            )}
          />
          <Route
            path={`/${href}`}
            exact
            render={() => (
              <Welcome>
                {iconName && <Icon name={iconName} size="72px" color={'#888'} />}
                <h4>
                  Select a{itemName.startsWith('i') && 'n'} {itemName} to edit or create a new one
                </h4>
              </Welcome>
            )}
          />
        </Switch>
      </Wrapper>
    )}
  </FirebaseList>
)

export default Collection
