import React, { Fragment } from 'react'
import { FormGroup, Label } from 'reactstrap'
import ActivitiesList from './ActivitiesList'
import Collection from './Collection'

const Editor = ({ fields, model, onChange }) => (
  <Fragment>
    <div style={{ flexBasis: '100%' }}>
      {fields.input('name')}
      {fields.textArea('description')}
      {fields.input('duration')}
      {fields.input('audience')}
    </div>
    <div style={{ flexBasis: '75%' }}>
      <FormGroup>
        <Label>Activites</Label>
        <ActivitiesList
          activities={model.activities || []}
          onChange={activities => onChange('activities', activities)}
        />
      </FormGroup>
    </div>
  </Fragment>
)

const Itineraries = () => (
  <Collection
    path="itineraries"
    prop="name"
    itemName="itinerary"
    iconName="format-list-bulleted"
    editor={Editor}
  />
)

export default Itineraries
