import React, { Fragment } from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import ListItem from './ListItem'
import ListSubheader from './ListSubheader'
import ListView from './ListView'
import _ from 'lodash'

const StyledListView = ListView.extend`
  flex-shrink: 0;
  align-self: stretch;
  margin: 16px 0 16px 16px;
  width: 250px;
  background: ${props => props.theme.lightBackground};
`

const Scrollable = styled.div`
  flex-grow: 1;
  overflow: auto;
`

const StyledListItem = styled(ListItem)`
  padding: 12px 16px;
`

const AddListItem = StyledListItem.extend`
  border-top: 1px solid ${props => props.theme.border} !important;
`

const StyledSubheader = styled(ListSubheader)`
  background: rgba(0, 0, 0, 0.03);
`

const ItemsSidebar = ({ items, itemName, prop = 'title', href, groupBy }) => {
  const listItem = item => (
    <StyledListItem key={item.id} activeClassName="active" to={`/${href}/${item.id}`}>
      {item[prop]}
    </StyledListItem>
  )

  const listItems = groupBy
    ? _.sortBy(Object.entries(groupBy(items)), 0).map(([label, items]) => (
        <Fragment key={label}>
          <StyledSubheader>{label}</StyledSubheader>
          <div>{_.sortBy(items, prop).map(listItem)}</div>
        </Fragment>
      ))
    : _.sortBy(items, prop).map(listItem)

  return (
    <StyledListView>
      <Scrollable>{listItems}</Scrollable>
      <AddListItem activeClassName="active" to={`/${href}/new`}>
        <Icon name="plus-circle" />
        Add new {itemName}
      </AddListItem>
    </StyledListView>
  )
}

export default ItemsSidebar
