import React from 'react'
import styled from 'styled-components'
import { Editor, RichUtils, EditorState, CompositeDecorator, SelectionState } from 'draft-js'
import getRangesForDraftEntity from 'draft-js/lib/getRangesForDraftEntity'
import { Button, ButtonGroup, Input, Popover, PopoverBody, PopoverHeader } from 'reactstrap'
import 'draft-js/dist/Draft.css'
import Icon from './Icon'
import { stateFromHTML } from 'draft-js-import-html'
import { stateToHTML } from 'draft-js-export-html'
import { getSelectionEntity } from 'draftjs-utils'

const Wrapper = styled.div`
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color ease-in-out 0.15s, box-shadow ease-in-out 0.15s;
  overflow: hidden;

  h4 {
    font-size: 17px;
    margin-bottom: 3px;
  }

  a {
    color: ${props => props.theme.highlight};
    text-decoration: underline;
  }

  .paragraph {
    margin-bottom: 8px;
  }
`

const Toolbar = styled.div`
  background: ${props => props.theme.lightBackground};
  border-bottom: 1px solid #ced4da;
  padding: 5px;
  margin: -0.375rem -0.75rem calc(0.375rem + 8px) -0.75rem;

  > button,
  > .btn-group {
    margin-right: 5px;
  }
`

const StyledIcon = styled(Icon)`
  color: ${props => props.theme.primaryText};
`

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  &.active {
    box-shadow: none !important;

    .mdi {
      color: white;
    }
  }
`

const ToolButton = ({ iconName, onClick, ...props }) => {
  return (
    <StyledButton outline color="secondary" size="sm" onClick={onClick} {...props}>
      <StyledIcon name={iconName} />
    </StyledButton>
  )
}

const INLINE_STYLES = [
  { iconName: 'format-bold', style: 'BOLD' },
  { iconName: 'format-italic', style: 'ITALIC' },
  { iconName: 'format-underline', style: 'UNDERLINE' }
]

const BLOCK_STYLES = [
  { iconName: 'format-align-left', style: 'unstyled' },
  { iconName: 'format-title', style: 'header-four' },
  { iconName: 'format-list-bulleted', style: 'unordered-list-item' }
]

const ControlsToolbar = ({
  editorState,
  onToggleInline,
  onToggleBlock,
  onEditLink,
  onRemoveLink
}) => {
  const selection = editorState.getSelection()
  const currentStyle = editorState.getCurrentInlineStyle()
  const blockType = editorState
    .getCurrentContent()
    .getBlockForKey(selection.getStartKey())
    .getType()
  const entityKey = getSelectionEntity(editorState)
  const entity = entityKey && editorState.getCurrentContent().getEntity(entityKey)
  const isLink = entity && entity.type === 'LINK'

  return (
    <Toolbar>
      <ButtonGroup>
        {INLINE_STYLES.map(({ iconName, style }) => (
          <ToolButton
            key={style}
            iconName={iconName}
            active={currentStyle.has(style)}
            onClick={() => onToggleInline(style)}
          />
        ))}
      </ButtonGroup>

      <ButtonGroup>
        {BLOCK_STYLES.map(({ iconName, style }) => (
          <ToolButton
            key={style}
            iconName={iconName}
            active={style === blockType}
            onClick={() => onToggleBlock(style)}
          />
        ))}
      </ButtonGroup>
      <ButtonGroup>
        <ToolButton
          id="link-button"
          iconName="link"
          onClick={onEditLink}
          disabled={!isLink && editorState.getSelection().isCollapsed()}
        />
        {isLink && <ToolButton iconName="link-off" onClick={onRemoveLink} />}
      </ButtonGroup>
    </Toolbar>
  )
}

function blockStyle(contentBlock) {
  const type = contentBlock.getType()
  if (type === 'unstyled') {
    return 'paragraph'
  }
}

function findLinkEntities(contentBlock, callback, contentState) {
  contentBlock.findEntityRanges(character => {
    const entityKey = character.getEntity()
    return entityKey !== null && contentState.getEntity(entityKey).getType() === 'LINK'
  }, callback)
}

function getEntitySelectionState(contentState, selectionState, entityKey) {
  const selectionKey = selectionState.getAnchorKey()
  const selectionOffset = selectionState.getAnchorOffset()
  const block = contentState.getBlockForKey(selectionKey)

  let entitySelection
  getRangesForDraftEntity(block, entityKey).forEach(range => {
    if (range.start <= selectionOffset && selectionOffset <= range.end) {
      entitySelection = new SelectionState({
        anchorOffset: range.start,
        anchorKey: selectionKey,
        focusOffset: range.end,
        focusKey: selectionKey,
        isBackward: false,
        hasFocus: selectionState.getHasFocus()
      })
    }
  })
  return entitySelection
}

const Link = props => {
  const { url } = props.contentState.getEntity(props.entityKey).getData()
  return <a href={url}>{props.children}</a>
}

const decorator = new CompositeDecorator([
  {
    strategy: findLinkEntities,
    component: Link
  }
])

export default class HtmlEditor extends React.Component {
  state = {
    previousContent: null,
    editorState: EditorState.createEmpty(decorator),
    html: null,
    linkEditorOpen: false,
    linkUrl: ''
  }

  handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command)
    if (newState) {
      this.onChange(newState)
      return 'handled'
    }
    return 'not-handled'
  }

  toggleInlineStyle = style => {
    this.onChange(RichUtils.toggleInlineStyle(this.state.editorState, style))
  }

  toggleBlockType = blockType => {
    this.onChange(RichUtils.toggleBlockType(this.state.editorState, blockType))
  }

  toggleLinkEditor = () => {
    if (this.state.linkEditorOpen) {
      this.saveLinkUrl()
    } else {
      const { editorState } = this.state

      const entityKey = getSelectionEntity(editorState)
      const entity = entityKey && editorState.getCurrentContent().getEntity(entityKey)
      const url = entity && entity.getData().url
      this.setState({ linkEditorOpen: true, linkUrl: url || '' })
    }
  }

  removeLink = () => {
    const { editorState } = this.state
    const entityKey = getSelectionEntity(editorState)
    let selection = getEntitySelectionState(
      editorState.getCurrentContent(),
      editorState.getSelection(),
      entityKey
    )

    this.onChange(RichUtils.toggleLink(editorState, selection, null))
  }

  onChange = editorState => {
    const content = editorState.getCurrentContent()

    if (content !== this.state.previousContent) {
      const html = stateToHTML(editorState.getCurrentContent())
      this.setState({ editorState, html, linkEditorOpen: false }, () => {
        this.props.onChange(html)
      })
    } else {
      this.setState({ editorState, linkEditorOpen: false })
    }
  }

  onUrlChange = e => this.setState({ linkUrl: e.target.value })

  onUrlKeyPress = e => {
    if (e.keyCode === 27) {
      this.setState({ linkEditorOpen: false })
    } else if (e.keyCode === 13) {
      this.saveLinkUrl()
    }
  }

  saveLinkUrl() {
    const { editorState, linkUrl } = this.state
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('LINK', 'MUTABLE', {
      url: linkUrl
    })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    const selection = editorState.getSelection().isCollapsed()
      ? getEntitySelectionState(
          editorState.getCurrentContent(),
          editorState.getSelection(),
          getSelectionEntity(editorState)
        )
      : editorState.getSelection()

    this.setState({
      editorState: RichUtils.toggleLink(newEditorState, selection, entityKey),
      linkEditorOpen: false
    })
  }

  setHtml(html) {
    const content = stateFromHTML(html || '')
    this.setState({
      html,
      previousContent: content,
      editorState: EditorState.createWithContent(content, decorator)
    })
  }

  componentWillReceiveProps({ html }) {
    if (html !== this.state.html) {
      this.setHtml(html)
    }
  }

  componentDidMount() {
    this.setHtml(this.props.html)
  }

  render() {
    const { editorState, linkUrl } = this.state

    return (
      <Wrapper>
        <ControlsToolbar
          editorState={editorState}
          onToggleInline={this.toggleInlineStyle}
          onToggleBlock={this.toggleBlockType}
          onEditLink={this.toggleLinkEditor}
          onRemoveLink={this.removeLink}
        />
        <Editor
          placeholder="No description"
          editorState={editorState}
          handleKeyCommand={this.handleKeyCommand}
          onChange={this.onChange}
          blockStyleFn={blockStyle}
          spellCheck
        />
        <Popover
          target="link-button"
          placement="bottom"
          isOpen={this.state.linkEditorOpen}
          toggle={this.toggleLinkEditor}
        >
          <PopoverHeader>{linkUrl ? 'Edit' : 'Add'} Link</PopoverHeader>
          <PopoverBody>
            <Input
              value={linkUrl}
              placeholder="http://example.com"
              onKeyDown={this.onUrlKeyPress}
              onChange={this.onUrlChange}
              autoFocus
            />
          </PopoverBody>
        </Popover>
      </Wrapper>
    )
  }
}
