import React from 'react'
import IndoorMapEditor from './IndoorMapEditor'
import OutdoorMapEditor from './OutdoorMapEditor'

const INDOOR_AREA_IDS = ['museum', 'visitorcenter', 'courthouse']

const isIndoorMap = poi => INDOOR_AREA_IDS.includes(poi.areaId)

const MapEditor = props =>
  isIndoorMap(props.pointOfInterest) ? (
    <IndoorMapEditor {...props} />
  ) : (
    <OutdoorMapEditor {...props} />
  )

export default MapEditor
