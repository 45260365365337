import React, { Fragment } from 'react'
import Collection from './Collection'
import { Alert } from 'reactstrap'

const Editor = ({ fields, model, isNew }) => (
  <Fragment>
    <div style={{ flexBasis: '100%' }}>
      {isNew && (
        <Alert color="secondary">
          Adding a new screen requires developer updates to the app. Please reach out to Lelander to
          link this new screen into the app.
        </Alert>
      )}
      {fields.input('title')}
      {fields.input('subhead')}
      {model.html &&
        fields.info(
          'Content',
          'This content screen uses HTML layouts and requires a developer to make updates to the content. Please reach out to Lelander for updates to this screen.'
        )}
      {!model.html && fields.html('content')}
      {fields.input('url', 'Learn More URL')}
    </div>
    <div style={{ flexBasis: '75%' }}>{fields.image('mainPhotoFilename', 'Main Photo')}</div>
  </Fragment>
)

const Screens = () => (
  <Collection path="screens" itemName="screen" iconName="cellphone" editor={Editor} />
)

export default Screens
