import styled from 'styled-components'

const ListSubheader = styled.h4`
  margin: 0;
  padding: 8px 16px;
  background: ${props => props.theme.lightBackground};
  border-bottom: 1px solid ${props => props.theme.border};
  font-size: 1rem;

  &:not(:first-child) {
    border-top: 1px solid ${props => props.theme.border};
  }
`

export default ListSubheader
