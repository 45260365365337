import React, { Fragment } from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import styled from 'styled-components'
import { groupedPointsOfInterest } from '../utils'
import { ListItem, ListSection, ListSubheader } from '../components'

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid ${props => props.theme.border};
  }

  .modal-footer {
    border-top: 1px solid ${props => props.theme.border};
  }

  .modal-body {
    padding: 0;
  }
`

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`

const PointsOfInterestModal = ({
  pointsOfInterest,
  excludePointsOfInterestIds,
  isOpen,
  onClose,
  onSelect
}) => {
  const groupedPOIs = groupedPointsOfInterest(
    pointsOfInterest,
    poi => !excludePointsOfInterestIds.includes(poi.id)
  )

  return (
    <StyledModal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Select a Point of Interest</ModalHeader>
      <ModalBody>
        {Object.entries(groupedPOIs).map(([areaName, exhibits]) => (
          <Fragment key={areaName}>
            <ListSubheader>{areaName}</ListSubheader>
            <ListSection>
              {exhibits.map(exhibit => (
                <StyledListItem key={exhibit.id} onClick={() => onSelect(exhibit)}>
                  {exhibit.name}
                </StyledListItem>
              ))}
            </ListSection>
          </Fragment>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </StyledModal>
  )
}

export default PointsOfInterestModal
