import styled from 'styled-components'

const AspectRatio = styled.div`
  position: relative;

  &::after {
    padding-top: ${props => 1 / props.ratio * 100}%;
    display: block;
    content: '';
  }

  > * {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
  }
`

export default AspectRatio
