import React from 'react'
import ActivityIndicator from './ActivityIndicator'
import StatusOverlay from './StatusOverlay'

const ActivityOverlay = ({ ...props }) => (
  <StatusOverlay {...props}>
    <ActivityIndicator />
  </StatusOverlay>
)

export default ActivityOverlay
