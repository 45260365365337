import React from 'react'
import {
  NavbarBrand,
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap'
import firebase from 'firebase'
import styled from 'styled-components'
import { Navbar, Icon, ActivityOverlay } from '../components'
import logo from '../gap_logo.png'

const Appbar = () => (
  <Navbar light expand="md">
    <NavbarBrand>Gateway Arch Park</NavbarBrand>
  </Navbar>
)

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`

const Centered = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
`

const Panel = styled.div`
  border: 1px solid ${props => props.theme.border};
  width: 350px;
  border-radius: 5px;
  overflow: hidden;
  padding: 16px;
  position: relative;
`

const PanelHeader = styled.h4`
  background: ${props => props.theme.lightBackground};
  border-bottom: 1px solid ${props => props.theme.border};
  padding: 8px 16px;
  font-size: 1.1rem;
  text-align: center;
  margin: -16px -16px 16px -16px;
`

const ErrorMessage = styled.p`
  font-weight: bold;
  color: #db3737;
`

const LogoWrapper = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
`

const Logo = styled.img`
  max-height: 250px;
  margin-bottom: 20px;
`

const Spacer = styled.div`
  flex-grow: 1;
  flex-basis: 0;
  align-self: stretch;
`

export default class SignIn extends React.Component {
  state = {
    email: '',
    password: '',
    error: null,
    busy: false
  }

  handleChange = event => this.setState({ [event.target.id]: event.target.value })

  signIn = event => {
    event.preventDefault()

    const { email, password } = this.state

    this.setState({ busy: true })

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.setState({ busy: false, error: 'Wrong username or password' })
      })
  }

  render() {
    const { email, password, error, busy } = this.state
    return (
      <Wrapper>
        <Appbar />
        <Centered>
          <LogoWrapper>
            <Logo src={logo} />
          </LogoWrapper>
          <Panel>
            <PanelHeader>Sign In</PanelHeader>
            <Form onSubmit={this.signIn}>
              <FormGroup>
                <Label for="email">Email address</Label>
                <InputGroup>
                  <InputGroupAddon>
                    <Icon name="email" />
                  </InputGroupAddon>
                  <Input
                    id="email"
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={this.handleChange}
                  />
                </InputGroup>
              </FormGroup>
              <FormGroup>
                <Label for="email">Password</Label>
                <InputGroup>
                  <InputGroupAddon>
                    <Icon name="key-variant" />
                  </InputGroupAddon>
                  <Input
                    id="password"
                    type="password"
                    placeholder="Password"
                    value={password}
                    onChange={this.handleChange}
                  />
                </InputGroup>
              </FormGroup>

              <ErrorMessage>{error}</ErrorMessage>

              <Button type="submit" color="primary" disabled={busy || !email || !password} block>
                Sign In
              </Button>
            </Form>

            {busy && <ActivityOverlay float />}
          </Panel>
          <Spacer />
        </Centered>
      </Wrapper>
    )
  }
}
