import React from 'react'
import { Collapse, NavbarToggler, NavbarBrand, Nav, NavItem, NavLink, Button } from 'reactstrap'
import { Link, NavLink as RouteLink } from 'react-router-dom'
import firebase from 'firebase'
import Icon from './Icon'
import Navbar from './Navbar'

export default class Appbar extends React.Component {
  state = {
    isOpen: false
  }

  toggle = () => this.setState({ isOpen: !this.state.isOpen })

  signOut = () => {
    firebase
      .auth()
      .signOut()
      .catch(error => console.error(error))
  }

  render() {
    return (
      <Navbar light expand="md">
        <NavbarBrand tag={Link} to="/">
          Gateway Arch Park
        </NavbarBrand>
        <NavbarToggler onClick={this.toggle} />
        <Collapse isOpen={this.state.isOpen} navbar>
          <Nav className="mr-auto" navbar>
            <NavItem>
              <NavLink tag={RouteLink} activeClassName="active" to="/screens">
                <Icon name="cellphone" />
                Screens
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouteLink} activeClassName="active" to="/exhibits">
                <Icon name="map-marker" />
                Points of Interest
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink tag={RouteLink} activeClassName="active" to="/itineraries">
                <Icon name="format-list-bulleted" />
                Itineraries
              </NavLink>
            </NavItem>
          </Nav>
          <Button onClick={this.signOut}>Sign Out</Button>
        </Collapse>
      </Navbar>
    )
  }
}
