import React from 'react'
import styled from 'styled-components'
import { compose, withProps } from 'recompose'
import { withScriptjs, withGoogleMap, GoogleMap, GroundOverlay, Marker } from 'react-google-maps'
import { FormGroup, Label } from 'reactstrap'
import IconButton from './IconButton'
import mapOverlay from '../map_overlay.png'
import mapMarker from '../map_marker.png'

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`

const Info = styled.p`
  color: ${props => props.theme.secondaryText};
  font-size: 0.75rem;
  margin: 0;
`

const Container = styled.div`
  border-radius: 4px;
  background: #eee;
  border: 1px solid #ccc;
  height: 500px;
  overflow: hidden;
  margin: 0.5rem 0;
`

const centerLat = 38.62451
const centerLong = -90.1869

const width = 0.011
const height = 0.01085

const northLat = centerLat + height / 2
const westLong = centerLong - width / 2
const southLat = centerLat - height / 2
const eastLong = centerLong + width / 2

const Map = compose(
  withProps({
    googleMapURL: `https://maps.googleapis.com/maps/api/js?v=3.exp&libraries=geometry,drawing,places&key=${
      process.env.REACT_APP_GOOGLE_MAPS_API_KEY
    }`,
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: <Container />,
    mapElement: <div style={{ height: `100%` }} />
  }),
  withScriptjs,
  withGoogleMap
)(({ children, ...props }) => (
  <GoogleMap defaultZoom={15} defaultCenter={{ lat: 38.625085, lng: -90.18781 }} {...props}>
    <GroundOverlay
      defaultUrl={mapOverlay}
      defaultBounds={
        new window.google.maps.LatLngBounds(
          new window.google.maps.LatLng(southLat, westLong),
          new window.google.maps.LatLng(northLat, eastLong)
        )
      }
      onClick={props.onClick}
    />
    {children}
  </GoogleMap>
))

export default class OutdoorMapEditor extends React.Component {
  onMapClicked = e => this.props.onChange({ latitude: e.latLng.lat(), longitude: e.latLng.lng() })

  onMarkerDragged = e =>
    this.props.onChange({
      latitude: e.latLng.lat(),
      longitude: e.latLng.lng()
    })

  removeMarker = () => this.props.onChange({ latitude: null, longitude: null })

  render() {
    const { pointOfInterest } = this.props
    const { latitude, longitude } = pointOfInterest

    const hasMarker = latitude != null && longitude != null

    return (
      <FormGroup>
        <StyledLabel>Map marker</StyledLabel>
        <Info>
          {hasMarker
            ? 'Drag the map marker or a click a new position.'
            : 'Click anywhere to add this POI to the map.'}
        </Info>
        <Map onClick={this.onMapClicked}>
          {hasMarker && (
            <Marker
              icon={mapMarker}
              position={{ lat: latitude, lng: longitude }}
              draggable
              onDragEnd={this.onMarkerDragged}
            />
          )}
        </Map>
        <IconButton
          color="danger"
          iconName="delete"
          disabled={!hasMarker}
          onClick={this.removeMarker}
        >
          Remove from map
        </IconButton>
      </FormGroup>
    )
  }
}
