import React from 'react'
import styled from 'styled-components'
import { Button } from 'reactstrap'
import Icon from './Icon'

const StyledButton = styled(Button)`
  display: inline-flex;
  align-items: center;
  justify-content: center;
`

const StyledIcon = styled(Icon)`
  margin-right: ${props => (props.padded ? '8px' : 0)};
`

const IconButton = ({ children, iconName, ...props }) => (
  <StyledButton {...props}>
    {iconName && (
      <StyledIcon name={iconName} padded={children != null} color={!props.outline && 'white'} />
    )}
    {children}
  </StyledButton>
)

export default IconButton
