import React from 'react'
import { Button, InputGroup, InputGroupAddon, InputGroupText, Input, Popover } from 'reactstrap'
import { SketchPicker } from 'react-color'
import Color from 'color'
import styled from 'styled-components'

const ARCHPARK_COLORS = [
  '#8EBE3F',
  '#14AA13',
  '#005A5A',

  '#00B0CA',
  '#0064AF',
  '#202B5E',

  '#C6168D',
  '#92278F',
  '#3E0D53',

  '#ED1C24',
  '#A92B31',
  '#56120F',

  '#ED8700',
  '#F2B600',
  '#E05206',

  '#9B9B9B',
  '#4E4E4E',
  '#000000'
]

const StyledSketchPicker = styled(SketchPicker)`
  background: none;
  border-radius: 0;
  box-shadow: none;
`

export default class ColorInput extends React.Component {
  state = { popoverOpen: false }

  togglePopover = () => this.setState(state => ({ popoverOpen: !state.popoverOpen }))

  render() {
    const { id, color, onChange } = this.props

    const borderColor =
      color &&
      Color(color)
        .darken(0.5)
        .hex()

    return (
      <InputGroup>
        <InputGroupAddon addonType="prepend">
          <InputGroupText style={{ width: 38, backgroundColor: color, borderColor }} />
        </InputGroupAddon>
        <Input
          id={id}
          placeholder="#RRGGBB"
          value={color}
          onChange={onChange}
          style={{ borderLeftColor: borderColor }}
        />
        <InputGroupAddon addonType="append">
          <Button id={id + '-button'} onClick={this.togglePopover}>
            Pick color...
          </Button>
        </InputGroupAddon>

        <Popover
          placement="bottom"
          isOpen={this.state.popoverOpen}
          target={id + '-button'}
          toggle={this.togglePopover}
        >
          <StyledSketchPicker
            color={color}
            onChangeComplete={onChange}
            disableAlpha
            presetColors={ARCHPARK_COLORS}
          />
        </Popover>
      </InputGroup>
    )
  }
}
