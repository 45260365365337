import React from 'react'
import ReactDOM from 'react-dom'
import 'mdi/css/materialdesignicons.css'
import './bootstrap.css'
import './styles'
import App from './App'
import './firebase'
import { unregister as unregisterServiceWorker } from './registerServiceWorker'

ReactDOM.render(<App />, document.getElementById('root'))
unregisterServiceWorker()
