import React from 'react'
import firebase from 'firebase'
import update from 'immutability-helper'
import _ from 'lodash'

export default class FirebaseList extends React.Component {
  state = {
    items: []
  }

  observe(path) {
    this.ref = firebase.database().ref(path)

    this.ref.on('child_added', snapshot => {
      const item = snapshot.val()
      item.id = snapshot.key
      this.setState({ items: this.state.items.concat([item]) })
    })
    this.ref.on('child_changed', snapshot => {
      const index = _.findIndex(this.state.items, item => item.id === snapshot.key)
      const item = snapshot.val()
      item.id = snapshot.key

      this.setState({ items: update(this.state.items, { $splice: [[index, 1, item]] }) })
    })

    this.ref.on('child_removed', snapshot => {
      this.setState({ items: this.state.items.filter(item => item.id !== snapshot.key) })
    })
  }

  async componentDidMount() {
    this.observe(this.props.path)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.path !== this.props.path) {
      this.ref.off()
      this.observe(newProps.path)
    }
  }

  componentWillUnmount() {
    this.ref.off()
  }

  render() {
    const { children, loading = null } = this.props
    const { items } = this.state

    if (items.length > 0) {
      return children(items)
    } else {
      return loading
    }
  }
}
