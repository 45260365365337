import React from 'react'
import styled from 'styled-components'
import Icon from './Icon'
import { colors } from '../styles'

const Wrapper = styled.div`
  display: flex;
  align-items: center;

  .mdi {
    margin-right: 8px;
  }
`

const Checkbox = ({ label, checked, onChange, ...props }) => (
  <Wrapper {...props} onClick={() => onChange(!checked)}>
    <Icon
      name={checked ? 'checkbox-marked' : 'checkbox-blank-outline'}
      color={checked ? colors.oceanBlue : colors.darkGreyBlue}
    />
    {label}
  </Wrapper>
)

export default Checkbox
