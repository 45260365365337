import React from 'react'
import firebase from 'firebase'

export default function withAuth(Component) {
  return class extends React.Component {
    state = {
      user: null,
      isSignedIn: null
    }

    async componentDidMount() {
      firebase.auth().onAuthStateChanged(user => {
        this.setState({ user, isSignedIn: user != null })
      })
    }

    render() {
      const { isSignedIn } = this.state

      if (isSignedIn != null) {
        return <Component {...this.props} {...this.state} />
      } else {
        return null
      }
    }
  }
}
