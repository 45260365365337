import styled from 'styled-components'

const ListView = styled.div`
  display: flex;
  flex-direction: column;
  border: 1px solid ${props => props.theme.border};
  border-radius: 3px;

  overflow: hidden;
`

export default ListView
