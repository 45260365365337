import React from 'react'
import { Route } from 'react-router-dom'
import styled from 'styled-components'
import { Appbar, Welcome } from '../components'
import Screens from './Screens'
import PointsOfInterest from './PointsOfInterest'
import Itineraries from './Itineraries'
import archParkIcon from '../arch_park.svg'

const Wrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
`

const Logo = styled.img``

const Main = ({ user }) => (
  <Wrapper>
    <Appbar user={user} />

    <Route
      exact
      path="/"
      render={() => (
        <Welcome>
          <Logo src={archParkIcon} />
          <h3>Welcome to the Arch Park CMS!</h3>
        </Welcome>
      )}
    />
    <Route path="/screens" component={Screens} />
    <Route path="/itineraries" component={Itineraries} />
    <Route path="/exhibits" component={PointsOfInterest} />
  </Wrapper>
)

export default Main
