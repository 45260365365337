import React from 'react'
import styled from 'styled-components'
import { SortableElement, SortableContainer, arrayMove } from 'react-sortable-hoc'
import _ from 'lodash'
import ical from 'ical'
import moment from 'moment'
import { Icon, ListItem, ListSection, ListView } from '../components'
import { withQuery } from '../hoc'
import PointsOfInterestModal from './PointsOfInterestModal'
import CalendarEventsModal from './CalendarEventsModal'

const Placeholder = styled.div`
  color: rgba(0, 0, 0, 0.54);
  padding: 12px 16px;
`

const StyledListItem = styled(ListItem)`
  background: #fdfdfd;
  margin: 0 -1px 0 -1px;
  cursor: row-resize;

  &:hover {
    background-color: #f9f9f9;
  }

  &.dragging {
    border: 1px solid ${props => props.theme.border};
    box-shadow: 0 5px 5px -5px rgba(0, 0, 0, 0.2), 0 -5px 5px -5px rgba(0, 0, 0, 0.2);
  }
`

const AddListItem = StyledListItem.extend`
  background: ${props => props.theme.lightBackground};
  border-top: 1px solid ${props => props.theme.border} !important;
  cursor: pointer;

  &:hover {
    background: #f3f3f3;
  }

  > .mdi {
    cursor: inherit;
  }
`

const ActivityListItem = SortableElement(({ activity, pointsOfInterest, onDelete }) => {
  const pointOfInterest =
    activity.pointOfInterestId &&
    _.find(pointsOfInterest, poi => poi.id === activity.pointOfInterestId)
  const event = activity.event

  return (
    <StyledListItem divider>
      <Icon name={pointOfInterest ? 'map-marker' : 'calendar'} />
      <span>{pointOfInterest ? pointOfInterest.name : event && event.summary}</span>
      <ListItem.Button onClick={() => onDelete(activity)}>
        <Icon name="delete" color="#dc3545" />
      </ListItem.Button>
    </StyledListItem>
  )
})

const SortableList = SortableContainer(({ activities, pointsOfInterest, onDelete }) => (
  <ListSection>
    {activities.map((activity, index) => (
      <ActivityListItem
        key={index}
        index={index}
        activity={activity}
        pointsOfInterest={pointsOfInterest}
        onDelete={onDelete}
      />
    ))}
  </ListSection>
))

class ActivitiesList extends React.Component {
  state = {
    isPOIModalOpen: false,
    isEventModalOpen: false,
    events: []
  }

  onSortEnd = ({ oldIndex, newIndex }) =>
    this.props.onChange(arrayMove(this.props.activities, oldIndex, newIndex))

  onDelete = deletedActivity =>
    this.props.onChange(this.props.activities.filter(activity => activity !== deletedActivity))

  openPOIModal = () => this.setState({ isPOIModalOpen: true })
  openEventModal = () => this.setState({ isEventModalOpen: true })

  closeModals = () => this.setState({ isPOIModalOpen: false, isEventModalOpen: false })

  addPointOfInterest = exhibit => {
    this.props.onChange([...this.props.activities, { pointOfInterestId: exhibit.id }])
    this.closeModals()
  }

  addEvent = event => {
    this.props.onChange([...this.props.activities, { event }])
    this.closeModals()
  }

  componentDidMount() {
    fetch('https://www.archpark.org/_utility/ical_ics')
      .then(response => response.text())
      .then(text => {
        const events = Object.values(ical.parseICS(text))
          .map(event => ({
            uid: event.uid,
            summary: event.summary,
            description: event.description,
            categories: event.categories,
            location: event.location,
            dtstamp: event.dtstamp && moment(event.dtstamp).format(),
            rdate: event.rdate ? event.rdate.map(date => moment(date).format()) : [],
            start: event.start.toISOString(),
            end: event.end.toISOString(),
            imageUrl: event['EVENT-IMAGE']
          }))
          .filter(event => moment(event.start).isAfter(moment()))

        this.setState({ events })
      })
  }

  render() {
    const { activities, pointsOfInterest } = this.props
    const { isPOIModalOpen, isEventModalOpen, events } = this.state

    const addedPointsOfInterestIds = activities
      .map(activity => activity.pointOfInterestId)
      .filter(id => id != null)
    const addedEventIds = activities
      .map(activity => activity.event && activity.event.uid)
      .filter(id => id != null)

    return (
      <ListView>
        <SortableList
          activities={activities}
          pointsOfInterest={pointsOfInterest}
          lockToContainerEdges
          lockAxis="y"
          helperClass="dragging"
          onSortEnd={this.onSortEnd}
          onDelete={this.onDelete}
        />
        {activities.length === 0 && <Placeholder>No activities yet</Placeholder>}
        <AddListItem onClick={this.openPOIModal}>
          <Icon name="plus-circle" />
          Add new point of interest
        </AddListItem>
        <AddListItem onClick={this.openEventModal}>
          <Icon name="calendar-plus" />
          Add new event
        </AddListItem>

        <PointsOfInterestModal
          pointsOfInterest={pointsOfInterest}
          excludePointsOfInterestIds={addedPointsOfInterestIds}
          isOpen={isPOIModalOpen}
          onClose={this.closeModals}
          onSelect={this.addPointOfInterest}
        />

        <CalendarEventsModal
          events={events}
          excludeEventIds={addedEventIds}
          isOpen={isEventModalOpen}
          onClose={this.closeModals}
          onSelect={this.addEvent}
        />
      </ListView>
    )
  }
}

export default withQuery(ActivitiesList, 'pointsOfInterest')
