import { Navbar } from 'reactstrap'
import styled from 'styled-components'

const StyledNavbar = styled(Navbar)`
  background-color: ${props => props.theme.lightBackground};
  border-bottom: 1px solid ${props => props.theme.border};

  .navbar-brand {
    font-family: 'Futura';
    text-transform: uppercase;
    color: ${props => props.theme.primaryText};
  }

  .nav-link,
  .nav-item {
    display: inline-flex;
    align-items: center;
    justify-content: center;

    .mdi,
    img {
      margin-right: 8px;
    }
  }
`

export default StyledNavbar
