import styled from 'styled-components'

const Welcome = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  flex: 1;

  .mdi,
  img {
    margin-bottom: 16px;
  }
`

export default Welcome
