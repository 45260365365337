import React from 'react'
import styled from 'styled-components'
import classnames from 'classnames'

const StyledIcon = styled.i`
  color: ${props => props.color || props.theme.icon};
  font-size: ${props => props.size};
  line-height: ${props => props.size};
  width: ${props => props.size};
  height: ${props => props.size};
`

const Icon = ({ name, className, size = '1.3rem', ...props }) => {
  const iconClass = classnames('mdi', `mdi-${name}`, className)

  return <StyledIcon className={iconClass} size={size} {...props} />
}

export default Icon
