import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import { DefaultTheme } from './styles'
import { withAuth } from './hoc'
import { Main, SignIn } from './containers'

const App = ({ user, isSignedIn }) => (
  <Router>
    <DefaultTheme>{isSignedIn ? <Main user={user} /> : <SignIn />}</DefaultTheme>
  </Router>
)

export default withAuth(App)
