import React, { Fragment } from 'react'
import styled from 'styled-components'
import Collection from './Collection'
import { groupedPointsOfInterest } from '../utils'
import { MapEditor } from '../components'

const TYPE_NAMES = [['area', 'Larger Area'], ['section', 'Section'], ['exhibit', 'Exhibit']]

const Row = styled.div`
  display: flex;

  > :not(:last-child) {
    margin-right: 16px;
  }
`

const Editor = ({ fields, model, updateModel, items }) => {
  const areaChoices = items.filter(poi => poi.type === 'area').map(poi => [poi.id, poi.name])
  const sectionChoices = items
    .filter(poi => poi.type === 'section' && poi.areaId === model.areaId)
    .map(poi => [poi.id, poi.name])

  return (
    <Fragment>
      <div style={{ flexBasis: '100%' }}>
        {fields.input('name')}
        {fields.checkbox('highlighted', 'Highlighted on the Explore screen')}
        <Row>
          {fields.dropdown('type', 'Type', TYPE_NAMES)}
          {(model.type === 'section' || model.type === 'exhibit') &&
            fields.dropdown('areaId', 'Area', areaChoices)}
          {model.type === 'exhibit' && fields.dropdown('sectionId', 'Section', sectionChoices)}
        </Row>
        {model.type === 'section' && fields.color('color')}
        {fields.html('description')}
        {fields.input('address')}
        {fields.textArea('hours')}
        {fields.input('duration')}
      </div>
      <div style={{ flexBasis: '75%' }}>
        {fields.image('mainPhotoFilename', 'Main Photo', true)}
        <MapEditor pointOfInterest={model} onChange={updateModel} />
      </div>
    </Fragment>
  )
}

const PointsOfInterest = () => (
  <Collection
    path="pointsOfInterest"
    href="exhibits"
    prop="name"
    itemName="point of interest"
    iconName="map-marker"
    groupBy={groupedPointsOfInterest}
    editor={Editor}
  />
)

export default PointsOfInterest
