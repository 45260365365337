import _ from 'lodash'

export function groupedPointsOfInterest(pointsOfInterest, filter) {
  return _(pointsOfInterest)
    .filter(poi => (filter ? filter(poi) : true))
    .groupBy(poi => poi.areaId || poi.id)
    .mapKeys((exhibits, areaId) => (_.find(pointsOfInterest, poi => poi.id === areaId) || {}).name)
    .value()
}

export function getRelativeMouseXY(event, elem = event.target.offsetParent) {
  let mouseX = 0,
    mouseY = 0,
    elementX = 0,
    elementY = 0

  if (event.pageX || event.pageY) {
    mouseX = event.pageX
    mouseY = event.pageY
  } else if (event.clientX || event.clientY) {
    mouseX = event.clientX + document.body.scrollLeft + document.documentElement.scrollLeft
    mouseY = event.clientY + document.body.scrollTop + document.documentElement.scrollTop
  }

  do {
    elementX += elem.offsetLeft - elem.scrollLeft
    elementY += elem.offsetTop - elem.scrollTop
    elem = elem.offsetParent
  } while (elem)

  return [mouseX - elementX, mouseY - elementY]
}
