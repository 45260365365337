import React from 'react'
import styled from 'styled-components'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap'
import color from 'color'
import _ from 'lodash'
import AspectRatio from './AspectRatio'
import IconButton from './IconButton'
import { colors } from '../styles'
import placeholderImage from '../placeholder.png'
import photoNames from '../photos.json'

const Wrapper = styled.div`
  position: relative;
`

const Image = styled.img`
  display: block;
  width: 100%;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 4px;
`

const Overlay = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background: ${color(colors.lightGrey)
    .alpha(0.8)
    .string()};
  border: 1px solid #ddd;
  border-radius: 4px;
  opacity: 0;
  transition: all 0.5s;

  > :not(:last-child) {
    margin-right: 8px;
  }

  &:hover {
    opacity: 1;
  }
`

const NoImage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border: 1px solid #ddd;
  border-radius: 4px;
  background: #eee;
  font-size: 1.1rem;
  color: rgba(0, 0, 0, 0.54);
`

const PhotoWrapper = styled.div`
  display: inline-block;
  width: calc(50% - 7.5px);
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 2px;
  margin-bottom: 15px;
  overflow: hidden;
  cursor: pointer;
  position: relative;

  &:nth-child(odd) {
    margin-right: 15px;
  }

  img {
    transition: all 0.25s;
    width: 100%;
  }

  &:hover img {
    transform: scale(1.1);
  }
`

const PhotoName = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  opacity: 0;
  color: white;
  text-shadow: 1px 1px 2px black;
  margin-top: 10px;
  transition: all 0.25s;
  font-size: 1.2rem;

  ${PhotoWrapper}:hover > & {
    opacity: 1;
    margin-top: 0;
  }
`

function imageDescription(filename) {
  return filename
    .replace(/\..*$/, '')
    .split('_')
    .map(_.capitalize)
    .join(' ')
}

class ImagePicker extends React.Component {
  state = {
    isModalOpen: false
  }

  openModal = () => this.setState({ isModalOpen: true })
  closeModal = () => this.setState({ isModalOpen: false })

  selectPhoto = filename => {
    this.closeModal()
    this.props.onChange(filename)
  }

  removePhoto = () => this.props.onChange(null)

  render() {
    const { filename, usePlaceholder } = this.props
    const { isModalOpen } = this.state

    const photoUrl = photoNames.includes(filename) && `/photos/${filename}`

    const overlay = (
      <Overlay>
        <IconButton iconName="image-multiple" onClick={this.openModal}>
          Select Photo
        </IconButton>
        {photoUrl && (
          <IconButton iconName="delete" color="danger" onClick={this.removePhoto}>
            Remove Photo
          </IconButton>
        )}
      </Overlay>
    )

    const modal = (
      <Modal isOpen={isModalOpen} toggle={this.closeModal} size="lg">
        <ModalHeader toggle={this.closeModal}>Select a Photo</ModalHeader>
        <ModalBody>
          {photoNames.map(filename => (
            <PhotoWrapper key={filename} onClick={() => this.selectPhoto(filename)}>
              <img src={`/photos/${filename}`} alt={imageDescription(filename)} />
              <PhotoName>{imageDescription(filename)}</PhotoName>
            </PhotoWrapper>
          ))}
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={this.closeModal}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    )

    return photoUrl || usePlaceholder ? (
      <Wrapper>
        <Image src={photoUrl || placeholderImage} />
        {overlay}
        {modal}
      </Wrapper>
    ) : (
      <AspectRatio ratio={750 / 640}>
        <NoImage>No image selected</NoImage>
        {overlay}
        {modal}
      </AspectRatio>
    )
  }
}

export default ImagePicker
