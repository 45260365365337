import React from 'react'
import { withRouter } from 'react-router-dom'
import firebase from 'firebase'
import styled from 'styled-components'
import {
  Alert,
  Button,
  FormGroup,
  Input,
  Label,
  UncontrolledAlert,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter
} from 'reactstrap'
import Textarea from 'react-textarea-autosize'
import {
  Checkbox,
  ColorInput,
  HtmlEditor,
  ImagePicker,
  IconButton,
  ActivityOverlay
} from '../components'
import _ from 'lodash'

const Wrapper = styled.div`
  flex-grow: 1;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 16px;
  position: relative;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  h3 {
    flex: 1;
    margin: 0;
  }

  .btn {
    margin-left: 8px;
  }
`

const Row = styled.div`
  display: flex;

  > * {
    align-self: start;
  }

  > :not(:last-child) {
    margin-right: 16px;
  }
`

const labelFromKey = key =>
  key.replace(/([A-Z])/g, ' $1').replace(/^./, function(str) {
    return str.toUpperCase()
  })

const labelForValue = (choices, value) => {
  const item = _.find(choices, choice => choice[0] === value)
  return item ? item[1] : 'None'
}

class EditorView extends React.Component {
  state = {
    model: null,
    hasChanges: false,
    error: null,
    busy: false,
    isDeleteModalOpen: false
  }

  fields = {
    input: (key, label = labelFromKey(key)) => (
      <FormGroup>
        <Label for={key}>{label}</Label>
        <Input
          id={key}
          placeholder={label}
          value={this.state.model[key] || ''}
          onChange={this.handleChange}
        />
      </FormGroup>
    ),
    textArea: (key, label = labelFromKey(key)) => (
      <FormGroup>
        <Label for={key}>{label}</Label>
        <Textarea
          id={key}
          className="form-control"
          placeholder={label}
          value={this.state.model[key] || ''}
          onChange={this.handleChange}
        />
      </FormGroup>
    ),
    checkbox: (key, label = labelFromKey(key)) => (
      <FormGroup>
        <Checkbox
          id={key}
          label={label}
          checked={this.state.model[key] || false}
          onChange={checked => this.onChange(key, checked)}
        />
      </FormGroup>
    ),
    color: (key, label = labelFromKey(key)) => (
      <FormGroup>
        <Label for={key}>{label}</Label>
        <ColorInput
          id={key}
          color={this.state.model[key]}
          onChange={color => this.onChange(key, color.hex)}
        />
      </FormGroup>
    ),
    dropdown: (key, label, choices) => (
      <FormGroup>
        <Label>{label}</Label>
        <UncontrolledDropdown>
          <DropdownToggle caret>{labelForValue(choices, this.state.model[key])}</DropdownToggle>
          <DropdownMenu right>
            {choices.map(([value, label]) => (
              <DropdownItem
                key={value}
                active={value === this.state.model[key]}
                onClick={() => this.onChange(key, value)}
              >
                {label}
              </DropdownItem>
            ))}
            {choices.length === 0 && <DropdownItem disabled>No choices</DropdownItem>}
          </DropdownMenu>
        </UncontrolledDropdown>
      </FormGroup>
    ),
    html: (key, label = labelFromKey(key)) => (
      <FormGroup>
        <Label>Content</Label>
        <HtmlEditor html={this.state.model[key]} onChange={html => this.onChange(key, html)} />
      </FormGroup>
    ),
    info: (label, text) => (
      <FormGroup>
        <Label>{label}</Label>
        <Alert color="secondary">{text}</Alert>
      </FormGroup>
    ),
    image: (key, label, usePlaceholder = false) => (
      <FormGroup>
        <Label>{label}</Label>
        <ImagePicker
          filename={this.state.model[key]}
          onChange={filename => this.onChange(key, filename)}
          usePlaceholder={usePlaceholder}
        />
      </FormGroup>
    )
  }

  updateModel = model => {
    this.setState(state => ({
      model: {
        ...state.model,
        ...model
      },
      hasChanges: true
    }))
  }

  onChange = (key, value) => this.updateModel({ [key]: value })

  handleChange = event => this.onChange(event.target.id, event.target.value)

  onEditorStateChange = (key, editorState) =>
    this.onChange(
      key,
      editorState,
      editorState.getCurrentContent() !== this.state.model[key].getCurrentContent()
    )

  onDeleteClicked = () => this.setState({ isDeleteModalOpen: true })

  onDeleteModalClose = () => this.setState({ isDeleteModalOpen: false })

  onConfirmDeleteClicked = async () => {
    this.setState({ busy: true, isDeleteModalOpen: false })

    try {
      await this.ref.remove()

      this.props.history.push(this.props.href.replace(/\/[^/]+$/, ''))
    } catch (error) {
      this.setState({ error, busy: false })
    }
  }

  saveChanges = async () => {
    this.setState({ busy: true })

    const model = this.state.model

    try {
      await this.ref.set(model)

      this.setState({
        savedModel: model,
        hasChanges: false,
        busy: false,
        error: null
      })
      this.props.history.push(this.props.href.replace('/new', `/${this.ref.key}`))
    } catch (error) {
      this.setState({ error, busy: false })
    }
  }

  resetChanges = () => {
    this.setState(state => {
      const model = state.savedModel

      return { model, hasChanges: false, error: null }
    })
  }

  observe(path) {
    if (path.endsWith('/new')) {
      this.ref = firebase
        .database()
        .ref(path.replace('/new', ''))
        .push()
    } else {
      this.ref = firebase.database().ref(path)
    }

    this.setState({ isNew: path.endsWith('/new') })

    this.ref.on('value', snapshot => {
      this.setState(state => {
        const savedModel = snapshot.val()
        const model = { ...savedModel, ...state.model }

        return { model, savedModel }
      })
    })
  }

  componentDidMount() {
    this.observe(this.props.path)
  }

  componentWillReceiveProps(newProps) {
    if (newProps.path !== this.props.path) {
      this.ref.off()
      this.setState({ model: null, hasChanges: false, error: null })
      this.observe(newProps.path)
    }
  }

  componentWillUnmount() {
    this.ref.off()
  }

  render() {
    const { isNew, model, error, busy, hasChanges, isDeleteModalOpen } = this.state
    const { render, name } = this.props

    if (model == null) {
      return null
    }

    const form = render({
      fields: this.fields,
      model,
      onChange: this.onChange,
      updateModel: this.updateModel,
      isNew
    })

    return (
      <Wrapper>
        {busy && <ActivityOverlay float />}
        <Header>
          <h3>
            {isNew ? 'New' : 'Edit'} {name}
          </h3>
          <IconButton
            iconName="check"
            color="primary"
            disabled={busy || !hasChanges}
            onClick={this.saveChanges}
          >
            Save Changes
          </IconButton>
          {!isNew && (
            <IconButton iconName="undo" disabled={busy || !hasChanges} onClick={this.resetChanges}>
              Undo Changes
            </IconButton>
          )}
          {!isNew && (
            <IconButton
              iconName="delete"
              color="danger"
              disabled={busy}
              onClick={this.onDeleteClicked}
            >
              Delete
            </IconButton>
          )}
        </Header>
        {error && <UncontrolledAlert color="danger">{error.message}</UncontrolledAlert>}
        <Row>{form}</Row>

        <Modal isOpen={isDeleteModalOpen} toggle={this.onDeleteModalClose}>
          <ModalHeader toggle={this.onDeleteModalClose}>Delete {name}</ModalHeader>
          <ModalBody>Are you sure you want to delete this {name.toLowerCase()}?</ModalBody>
          <ModalFooter>
            <Button color="secondary" onClick={this.onDeleteModalClose}>
              Cancel
            </Button>
            <Button color="danger" onClick={this.onConfirmDeleteClicked}>
              Delete
            </Button>
          </ModalFooter>
        </Modal>
      </Wrapper>
    )
  }
}

export default withRouter(EditorView)
