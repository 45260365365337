import React from 'react'
import { injectGlobal, ThemeProvider } from 'styled-components'

export const colors = {
  darkGreyBlue: '#2d4553',
  cerulean: '#0171bb',
  midBlue: '#1e6ca7',
  oceanBlue: '#056bae',
  lightNavy: '#142d6d',
  charcoalGrey: '#4a4a4a',
  warmGrey50: 'rgba(151, 151, 151, 0.5)',
  lightGrey: '#f7f7f7',
  orange: '#f17703',
  white: '#ffffff',
  turquoiseBlue: '#00aedb',
  darkYellow: '#deb406',
  brownishOrange: '#d85527',
  lightOliveGreen: '#a0b75c',
  sicklyYellow: '#c3d021',
  tealish: '#2ab0c3',
  tealishTwo: '#259dad',
  duckEggBlue: '#b5e9f7',
  warmGrey: '#8c8c8c',
  perrywinkle: '#8dbfe4',
  warmGreyTwo: '#858585',
  whiteTwo: '#d8d8d8',
  vividPurple70: 'rgba(144, 19, 254, 0.7)',
  vividPurple: '#9012fe',
  grassyGreen70: 'rgba(46, 153, 3, 0.7)',
  grassyGreen: '#2e9903',
  appleGreen: '#7ed321',
  darkishGreen: '#1f7a42',
  greyishBrown: '#4e4e4e'
}

export const lightTheme = {
  lightBackground: colors.lightGrey,
  primaryText: colors.darkGreyBlue,
  secondaryText: colors.charcoalGrey,
  button: colors.oceanBlue,
  highlight: colors.oceanBlue,
  icon: colors.oceanBlue,
  border: colors.whiteTwo,
  indicator: colors.darkGreyBlue
}

injectGlobal`
  html, body, #root {
    height: 100%;
  }
`

export const DefaultTheme = ({ children }) => (
  <ThemeProvider theme={lightTheme}>{children}</ThemeProvider>
)
