import firebase from 'firebase'

const DEV_CONFIG = {
  apiKey: 'AIzaSyAnx8myp-Zwy5-UENPalQQ_C_9pJTtsNZs',
  authDomain: 'cityarchriver-dev.firebaseapp.com',
  databaseURL: 'https://cityarchriver-dev.firebaseio.com',
  projectId: 'cityarchriver-dev',
  storageBucket: 'cityarchriver-dev.appspot.com',
  messagingSenderId: '860487772505'
}

const PRODUCTION_CONFIG = {
  apiKey: 'AIzaSyD2KmHlxEprSnkfBsm2hGJXYDMudBoBy8Q',
  authDomain: 'gatewayarchpark.firebaseapp.com',
  databaseURL: 'https://gatewayarchpark.firebaseio.com',
  projectId: 'gatewayarchpark',
  storageBucket: 'gatewayarchpark.appspot.com',
  messagingSenderId: '559562617170'
}

firebase.initializeApp(process.env.REACT_APP_ENV === 'prod' ? PRODUCTION_CONFIG : DEV_CONFIG)
