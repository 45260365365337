import React from 'react'
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap'
import styled from 'styled-components'
import { ListItem } from '../components'

const StyledModal = styled(Modal)`
  .modal-header {
    border-bottom: 1px solid ${props => props.theme.border};
  }

  .modal-footer {
    border-top: 1px solid ${props => props.theme.border};
  }

  .modal-body {
    padding: 0;
  }
`

const StyledListItem = styled(ListItem)`
  cursor: pointer;
`

const CalendarEventsModal = ({ events, excludeEventIds, isOpen, onClose, onSelect }) => {
  const eventsToShow = events.filter(event => !excludeEventIds.includes(event.uid))

  return (
    <StyledModal isOpen={isOpen} toggle={onClose}>
      <ModalHeader toggle={onClose}>Select an Event</ModalHeader>
      <ModalBody>
        {eventsToShow.map(event => (
          <StyledListItem key={event.uid} onClick={() => onSelect(event)}>
            {event.summary}
          </StyledListItem>
        ))}
      </ModalBody>
      <ModalFooter>
        <Button color="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </StyledModal>
  )
}

export default CalendarEventsModal
