import React from 'react'
import firebase from 'firebase'

export default function withQuery(Component, path) {
  return class extends React.Component {
    state = { [path]: [] }

    async componentDidMount() {
      this.ref = firebase.database().ref(path)

      this.ref.on('child_added', snapshot => {
        const item = snapshot.val()
        item.id = snapshot.key
        this.setState({ [path]: this.state[path].concat([item]) })
      })
    }

    componentWillUnmount() {
      this.ref.off()
    }

    render() {
      if (this.state[path].length > 0) {
        return <Component {...this.props} {...this.state} />
      } else {
        return null
      }
    }
  }
}
