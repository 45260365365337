import React from 'react'
import styled, { css } from 'styled-components'
import { FormGroup, Label } from 'reactstrap'
import Draggable from 'react-draggable'
import _ from 'lodash'
import marker from '../map_marker.png'
import { getRelativeMouseXY } from '../utils'
import { colors } from '../styles'

const MUSEUM_VISITORCENTER_LEVELS = [
  { name: 'Level 3 - Entry', level: 3, src: require('../maps/museum_entry.png') },
  { name: 'Level 2 - Mezzanine', level: 2, src: require('../maps/museum_mezzanine.png') },
  { name: 'Level 1 - Main', level: 1, src: require('../maps/museum_main.png') }
]

const COURTHOUSE_LEVELS = [
  { name: 'Floor 1', level: 1, src: require('../maps/courthouse1.png') },
  { name: 'Floor 2', level: 2, src: require('../maps/courthouse2.png') }
]

const MAPS = {
  museum: MUSEUM_VISITORCENTER_LEVELS,
  visitorcenter: MUSEUM_VISITORCENTER_LEVELS,
  courthouse: COURTHOUSE_LEVELS
}

const StyledLabel = styled(Label)`
  margin-bottom: 0;
`

const Info = styled.p`
  color: ${props => props.theme.secondaryText};
  font-size: 0.75rem;
  margin-top: 0;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  position: relative;
  margin-bottom: 0.5rem;
  overflow: hidden;

  img {
    width: 100%;
  }
`

const TabBar = styled.div`
  display: flex;
  background-color: ${props => props.theme.lightBackground};
  border-top: 1px solid ${props => props.theme.border};
  border-left: 1px solid ${props => props.theme.border};
  border-right: 1px solid ${props => props.theme.border};
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
`

const Tab = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  height: 36px;
  color: ${props => (props.active ? props.theme.primaryText : props.theme.secondaryText)};
  font-size: 0.9rem;

  border-top: 3px solid transparent;
  border-bottom: 3px solid ${props => (props.active ? colors.orange : 'transparent')};
  cursor: pointer;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    color: ${props => props.theme.highlight};
  }

  ${props =>
    props.active &&
    css`
      background-color: rgba(0, 0, 0, 0.05) !important;
    `};
`

const Marker = styled.div`
  position: absolute;
  width: 24px;
  height: 31px;
  background: url('${marker}');
  background-size: contain;
  top: -31px;
  left: -12px;

  ${props =>
    props.dimmed &&
    css`
      opacity: 0.5;
    `}
`

export default class IndoorMapEditor extends React.Component {
  state = { mapLoaded: false, width: 0, height: 0 }

  onMapLoaded = event =>
    this.setState({
      mapLoaded: true,
      width: event.target.naturalWidth / 4,
      height: event.target.naturalHeight / 4
    })

  onClicked = event => {
    const [eventX, eventY] = getRelativeMouseXY(event)

    const x = eventX / this.mapView.offsetWidth * this.state.width
    const y = eventY / this.mapView.offsetHeight * this.state.height

    this.props.onChange({ x, y, level: this.level.level })
  }

  onDragged = (event, data) => {
    console.log(event, data)
    const x = data.x / this.mapView.offsetWidth * this.state.width
    const y = data.y / this.mapView.offsetHeight * this.state.height

    this.props.onChange({ x, y, level: this.level.level })
  }

  onLevelClicked = level => this.props.onChange({ level, x: null, y: null })

  get levels() {
    const { pointOfInterest } = this.props

    return MAPS[pointOfInterest.areaId]
  }

  get level() {
    const { pointOfInterest } = this.props

    return _.find(this.levels, level => level.level === pointOfInterest.level) || this.levels[0]
  }

  render() {
    const { pointOfInterest } = this.props
    const { mapLoaded, width, height } = this.state

    const hasMarker = pointOfInterest.x != null && pointOfInterest.y != null
    const scaleX = this.mapView ? width / this.mapView.offsetWidth : 1
    const scaleY = this.mapView ? height / this.mapView.offsetHeight : 1

    return (
      <FormGroup>
        <StyledLabel>Map marker</StyledLabel>
        <Info>
          {hasMarker
            ? 'Drag the map marker or a click a new position.'
            : 'Click anywhere to add this POI to the map.'}
        </Info>
        <TabBar>
          {this.levels.map(aLevel => (
            <Tab
              key={aLevel.level}
              active={aLevel === this.level}
              onClick={() => this.onLevelClicked(aLevel.level)}
            >
              {aLevel.name}
            </Tab>
          ))}
        </TabBar>
        <Wrapper innerRef={map => (this.mapView = map)}>
          <img src={this.level.src} alt="Map" onLoad={this.onMapLoaded} onClick={this.onClicked} />
          {mapLoaded &&
            hasMarker && (
              <Draggable
                position={{ x: pointOfInterest.x / scaleX, y: pointOfInterest.y / scaleY }}
                onStop={this.onDragged}
              >
                <Marker />
              </Draggable>
            )}
        </Wrapper>
      </FormGroup>
    )
  }
}
