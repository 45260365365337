import styled, { keyframes } from 'styled-components'
import { colors } from '../styles'

const animation = keyframes`
  0%,
  100% {
    box-shadow: 0 0 0 ${colors.darkGreyBlue},
                0 0 0 ${colors.darkGreyBlue};
  }
  50% {
    box-shadow: 0 -.5em 0 ${colors.darkGreyBlue},
                0 .5em 0 ${colors.darkGreyBlue};
  }
`

const ActivityIndicator = styled.div`
  background: ${props => props.theme.indicator};
  position: relative;
  animation: ${animation} 1s ease-in-out infinite;
  animation-delay: 0.4s;
  width: 0.5em;
  height: 1em;
  margin: 0 1em;
  &:after,
  &:before {
    content: '';
    position: absolute;
    width: inherit;
    height: inherit;
    background: inherit;
    animation: inherit;
  }
  &:before {
    right: 1em;
    animation-delay: 0.2s;
  }
  &:after {
    left: 1em;
    animation-delay: 0.6s;
  }
`

export default ActivityIndicator
