import React from 'react'
import styled, { css } from 'styled-components'
import { NavLink } from 'react-router-dom'

const style = css`
  display: flex;
  align-items: center;
  color: ${props => props.theme.primaryText};
  padding: 8px 16px;
  transition: all 0.25s;

  &:hover {
    background-color: rgba(0, 0, 0, 0.02);
    color: ${props => props.theme.highlight};
    text-decoration: none;
  }

  &.active {
    background-color: rgba(0, 0, 0, 0.05);
  }

  > span {
    flex: 1;
  }

  > .mdi:first-child {
    margin-right: 8px;
  }

  ${props =>
    props.divider
      ? css`
          &:not(:last-child) {
            border-bottom: 0.5px solid ${props => props.theme.border};
          }
        `
      : css`
          &:not(:first-child) {
            border-top: 0.5px solid transparent;
          }

          &:not(:last-child) {
            border-bottom: 0.5px solid transparent;
          }

          &:hover,
          .active {
            border-color: ${props => props.theme.border};
          }
        `};
`

const BasicListItem = styled.li`
  ${style};
`

const ListItemLink = styled(NavLink)`
  ${style};
`

const ListItem = ({ to, ...props }) =>
  to ? <ListItemLink to={to} {...props} /> : <BasicListItem {...props} />

ListItem.Button = styled.button`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  background: none;
  border: none;
  border-radius: 4px;
  padding: 6px;
  transition: all 0.25s;
  margin-left: 8px;

  &:hover {
    background-color: #e6e6e6;
    border-color: #adadad;
  }

  &:active {
    background-color: #ccc;
    border-color: #999;
  }
`

export default ListItem
