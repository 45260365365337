import React from 'react'
import styled, { css } from 'styled-components'
import color from 'color'
import { colors } from '../styles'

const OuterWrapper = styled.div`
  position: relative;

  ${props =>
    props.fillParent &&
    css`
      flex-grow: 1;
      align-self: stretch;
    `};
  ${props =>
    props.float &&
    css`
      background: ${color(colors.lightGrey)
        .alpha(0.5)
        .string()};
      z-index: 100;
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    `};
  ${props =>
    !props.fillParent &&
    !props.float &&
    css`
      min-width: 300px;
      min-height: 200px;
      padding-bottom: 50%;
    `};
  ${props =>
    props.rounded &&
    css`
      border-radius: 4px;
    `};
`

const InnerWrapper = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const StatusOverlay = ({ children, ...props }) => (
  <OuterWrapper {...props}>
    <InnerWrapper>{children}</InnerWrapper>
  </OuterWrapper>
)

export default StatusOverlay
